<template>
  <v-form ref="form">
    <dialog-view :open="dataState.isModalOpen" title="Блокировка времени" @cancel="close()">
      <template v-slot:content v-if="dataState.modalData!==null">
        <div class="_log-wrapper">
          <log entity-name="Visit" :entity-id="dataState.modalData.id"/>
        </div>
        <v-row>
          <v-col cols="12">
            <v-list-item class="mx-n4 my-n2">
              <v-list-item-content>
                <v-list-item-title>{{dataState.modalData.comPlace.comRoom.sid}} {{dataState.modalData.comPlace.comRoom.name}}</v-list-item-title>
                <v-list-item-subtitle class="text-wrap">
                  <span class="mr-1">Местоположение:</span>
                  {{dataState.modalData.comPlace.comRoom.comFloor.name}}
                  <v-icon small>mdi-chevron-left</v-icon>
                  {{dataState.modalData.comPlace.comRoom.comFloor.comBuilding.comBranch.name}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-wrap">
                  <span class="mr-1">Рабочее место:</span>
                  <span class="mr-1">
                    {{dataState.modalData.comPlace.name}}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn icon color="blue" class="mr-2" :class="dataState.modalData.mark==='blue' ? 'v-btn--active' : ''" @click="dataState.modalData.mark='blue'"><v-icon>mdi-lock</v-icon></v-btn>
            <v-btn icon color="green" class="mr-2" :class="dataState.modalData.mark==='green' ? 'v-btn--active' : ''" @click="dataState.modalData.mark='green'"><v-icon>mdi-lock</v-icon></v-btn>
            <v-btn icon color="red" :class="dataState.modalData.mark==='red' ? 'v-btn--active' : ''" @click="dataState.modalData.mark='red'"><v-icon>mdi-lock</v-icon></v-btn>
          </v-col>
          <v-col cols="12">
            <time-range :loadState.sync="loadState"  :begin.sync="dataState.modalData.begin" :end.sync="dataState.modalData.end" :dayTimeBegin.sync="userSpecPlanTimeRange&&userSpecPlanTimeRange.begin" :dayTimeEnd.sync="userSpecPlanTimeRange&&userSpecPlanTimeRange.end"/>
          </v-col>

          <v-col cols="12">
            <v-textarea v-model="dataState.modalData.comment" label="Комментарий" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"/>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions v-if="dataState.modalData!==null">
        <dialog-view
            v-if="!!dataState.modalData.id"
            :title="'Удалить блокировку времени #'+dataState.modalData.id+'?'"
            acceptName="Удалить"
            @acceptAction="del()"
            :loading="deleteState.isLoading"
        >
          <template v-slot:activator>
            <v-btn color="error" text class="mr-2">Удалить</v-btn>
          </template>
        </dialog-view>
        <v-btn
            @click="save()"
            :loading="createUpdateState.isLoading"
            color="primary" text>
          {{typeof dataState.modalData.id==='undefined' ? 'Создать' : 'Сохранить'}}
        </v-btn>
      </template>
    </dialog-view>
  </v-form>
</template>


<script>
import State from "@/plugins/state";
import DialogView from "@/components/DialogView";
import {FormFieldRules} from "@/plugins/formFieldRules";
import moment from "moment";
import axios from "axios";
import Api from "@/Api";
import Log from "@/views/Log/Log";
import TimeRange from "@/components/TimeRange";
import {Entity_UserSpecPlan, Entity_ComPlace} from "../../../EntityStoreCacheService";

export default {
  components: {DialogView,TimeRange,Log},
  props:['state'],
  data: () => ({
    dataState: new State(),
    createUpdateState: new State(),
    deleteState: new State(),
    formFieldRules: FormFieldRules,
    loadState: new State(),
    userSpecPlanTimeRange: null
  }),
  watch:{
    state: {
      deep: true,
      handler() {
        if(this.state.isModalOpen){
          this.getUserSpecPlanTimeRange(this.state.modalData.begin,this.state.modalData.end,this.state.modalData.comPlace.id);

          this.dataState.modalOpen({
            id:this.state.modalData.id,
            comPlace:this.state.modalData.comPlace,
            mark:this.state.modalData.mark,
            date:moment(this.state.modalData.begin).format('YYYY-MM-DD'),
            begin:moment(this.state.modalData.begin).format('HH:mm'),
            end:moment(this.state.modalData.end).format('HH:mm'),
            comment:this.state.modalData.comment,
          });

          this.$refs.form.resetValidation();
        }
      },
    },
  },
  methods: {
      save() {
      this.createUpdateState.stateLoading();
      if(typeof this.dataState.modalData.id==='undefined'){
        axios.post(Api.host+'/visit/', {
          begin: this.dataState.modalData.date+' '+this.dataState.modalData.begin+':00',
          end: this.dataState.modalData.date+' '+this.dataState.modalData.end+':00',
          comPlace: this.dataState.modalData.comPlace.id,
          comment: this.dataState.modalData.comment,
          mark: this.dataState.modalData.mark,
        })
            .then(()=> {
              this.createUpdateState.stateSuccess();
              this.$store.commit('alertSuccess', 'Заблокированное время сохранено');
              this.close();
            })
            .catch((error)=> {
              this.createUpdateState.stateError();
              console.log(error)
            });
      } else {
        axios.put(Api.host+'/visit/'+this.dataState.modalData.id+'/', {
          begin: this.dataState.modalData.date+' '+this.dataState.modalData.begin+':00',
          end: this.dataState.modalData.date+' '+this.dataState.modalData.end+':00',
          comPlace: this.dataState.modalData.comPlace.id,
          comment: this.dataState.modalData.comment,
          mark: this.dataState.modalData.mark,
        })
            .then(()=> {
              this.createUpdateState.stateSuccess();
              this.$store.commit('alertSuccess', 'Заблокированное время сохранено');
              this.close();
            })
            .catch((error)=> {
              this.createUpdateState.stateError();
              console.log(error)
            });
      }
    },
    del() {
      this.deleteState.stateLoading();
      axios.delete(Api.host+'/visit/'+this.dataState.modalData.id+'/')
          .then(()=> {
            this.deleteState.stateSuccess();
            this.$store.commit('alertSuccess', 'Заблокированное время сохранено');
            this.close();
          })
          .catch((error)=> {
            this.deleteState.stateError();
            console.log(error)
          });
    },

    close(){
      this.dataState.modalClose();
      this.$emit('cancel');
    },
    getUserSpecPlanTimeRange(begin,end,complaceId) {
      //Получает время начала и конца выбранного плана работы, чтобы ограничить компонент выбора времени в визите/блокировке.
      this.loadState.stateLoading();
      this.userSpecPlanTimeRange = null;
      let builder = new Entity_UserSpecPlan()
      .selects(Entity_UserSpecPlan.id)
      .selects(Entity_UserSpecPlan.begin)
      .selects(Entity_UserSpecPlan.end)

      builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.begin].join('.')+'<=?',moment(begin));
      builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.end].join('.')+'>=?',moment(end));
      builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.comPlace, Entity_ComPlace.id].join('.')+' in (?)',[parseInt(complaceId)]);

      builder.page(1).onPage(3000);

      builder.request((data)=>{
        if (data.count>0) {
          this.userSpecPlanTimeRange = {
            begin: this.$tools.dateTimeFormat(data.items[0].begin, 'HH:mm'),
            end: this.$tools.dateTimeFormat(data.items[0].end, 'HH:mm')
          }
        }
        this.loadState.stateSuccess();
      }, (e)=>{
        console.error(e.response.data.message);
        this.loadState.stateError(e)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  ._log-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    border-bottom:1px solid #e0e0e0;
    padding-bottom: 15px;
  }
</style>