<template>
 <div style="position:absolute; width:100%;">
   <v-toolbar class="bar" dense elevation="0" color="transparent" max-height="48px">
     <v-btn icon small @click="$emit('cancel')">
       <v-icon small>mdi-close</v-icon>
     </v-btn>
     <v-toolbar-title class="body-2 pl-2 font-weight-medium">{{liveList ? 'Лист живой очереди' : 'Лист ожидания'}}</v-toolbar-title>
     <v-spacer/>
     <v-btn @click="clientSearchState.modalOpen()" depressed small>Добавить</v-btn>
   </v-toolbar>

   <v-divider></v-divider>

   <v-toolbar class="bar" dense elevation="0" color="transparent" v-if="!liveList">
    <v-checkbox
        v-model="currentDay"
        label="Только текущий день"
        class="mt-4"
      ></v-checkbox>
  </v-toolbar> 
   <div v-if="dataState.isLoading" class="text-center pt-4">
     <v-progress-circular indeterminate color="primary"/>
   </div>
   <div v-if="dataState.isSuccess">
     <v-sheet :height="height-48" class="overflow-y-auto pb-15">
       <v-chip v-if="filterUserSpec!==null" class="mb-2 mx-4" small :close="true" @click:close="$emit('update:filterUserSpec', null);load()">
         {{filterUserSpec.user.lastName=== null ? '': filterUserSpec.user.lastName}}
         {{filterUserSpec.user.firstName=== null ? '': filterUserSpec.user.firstName}}
         {{filterUserSpec.user.secondName=== null ? '': filterUserSpec.user.secondName}}
       </v-chip>
       <v-chip v-if="filterSpec!==null" class="mb-2 mx-4" small :close="true" @click:close="$emit('update:filterSpec', null);load()">
         {{filterSpec.name}}
       </v-chip>
       <v-list v-if="visitPlans.length>0" class="pa-0">
         <v-list-item
             v-for="(visitPlan, k) in visitPlans" :key="'visitPlan'+k"
             @click="visitPlanState.modalOpen(visitPlan)">
           <v-list-item-content>
             <v-list-item-title>
               <div class="font-weight-medium mb-1">
                 {{visitPlan.user.lastName=== null ? '': visitPlan.user.lastName}}
                 {{visitPlan.user.firstName=== null ? '': visitPlan.user.firstName}}
                 {{visitPlan.user.secondName=== null ? '': visitPlan.user.secondName}}
               </div>
               <div class="caption mb-1">К специалисту{{visitPlan.isLiveList ? '': ' '+$tools.date(visitPlan.begin)}}:</div>
               <v-row v-if="visitPlan.userSpec!==null" no-gutters class="align-center">
                 <v-col cols="auto">
                   <v-avatar class="user" size="32">
                     <img :src="visitPlan.userSpec.user.imageUrl" v-if="visitPlan.userSpec.user.imageUrl" alt="">
                   </v-avatar>
                 </v-col>
                 <v-col class="pl-2">
                   <div class="font-weight-medium">
                     {{visitPlan.userSpec.user.lastName=== null ? '': visitPlan.userSpec.user.lastName}}
                     {{visitPlan.userSpec.user.firstName=== null ? '': visitPlan.userSpec.user.firstName}}
                     {{visitPlan.userSpec.user.secondName=== null ? '': visitPlan.userSpec.user.secondName}}
                   </div>
                   <div class="caption text-wrap">
                     {{visitPlan.userSpec.spec.name}}
                     <v-icon small>mdi-chevron-left</v-icon>
                     {{visitPlan.userSpec.comDiv.comBranch.name}}
                     <div v-if="!$tools.isEmpty(visitPlan.userSpec.commentAdmin)" class="caption">
                       <v-icon class="mr-1" small>mdi-comment-text-outline</v-icon>
                       {{visitPlan.userSpec.commentAdmin}}
                     </div>
                   </div>
                 </v-col>
               </v-row>
               <div v-else class="font-weight-medium">
                 {{visitPlan.spec.name}}
               </div>
               <div v-if="!$tools.isEmpty(visitPlan.comment)" class="mt-1">
                 <div class="caption">Комментарий:</div>
                 <div class="caption">{{visitPlan.comment}}</div>
               </div>
             </v-list-item-title>
           </v-list-item-content>
         </v-list-item>
       </v-list>
       <alert-view v-else icon="mdi-information-outline" text="Не найдено" />
     </v-sheet>
   </div>
   <visit-plan
       :state="visitPlanState"
       :users="users"
       :specs="specs"
       @cancel="visitPlanState.modalClose()"
       @createdUpdatedDeleted="visitPlanState.modalClose();load();$emit('visitPlansUpdated')"
       @visitCreate="visitCreate"
   />
   <client-search-or-create
       :open="clientSearchState.isModalOpen"
       :dataFromCaller.sync="dataFromCaller"
       @cancel="clientSearchState.modalClose();load();$emit('clear-data-from-caller')"
       @success="clientSelected"
   />
 </div>
</template>

<script>
import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
import AlertView from "@/components/AlertView";
import VisitPlan from "@/views/Visit/VisitPlan";
import ClientSearchOrCreate from "@/views/User/Client/ClientSearchOrCreate";
import moment from "moment";

export default {
  components: {ClientSearchOrCreate, VisitPlan, AlertView},
  props:['height','users','specs','filterUserSpec','filterSpec','daySelected','liveList','dataFromCaller','branches'],
  data: () => ({
    dataState: new State(),
    visitPlans:[],
    visitPlanState: new State(),
    clientSearchState: new State(),
    currentDay: true
  }),
  mounted() {
    this.load();
  },
  watch:{
    dataFromCaller() {
      this.load()
    },
    daySelected(){
      this.load();
    },
    liveList(){
      this.load();
    },
    filterUserSpec(){
      this.load();
    },
    filterSpec(){
      this.load();
    },
    branches() {
      this.load();
    },
    currentDay() {
      this.load();
    }
  },
  methods:{
    openSearchModalOrAddCurrentUser(){ 
      if (this.dataFromCaller.user !== null&&this.dataFromCaller.type !== null) {
        this.clientSelected(this.dataFromCaller.user)
                
        //clear data from caller
        this.$emit('clear-data-from-caller')

      } else if (this.dataFromCaller.phone !== null&&this.dataFromCaller.type !== null) {
        this.clientSearchState.modalOpen();
      }
    },
    load(){
      this.dataState.stateLoading();
      axios.get(Api.host+'/visitPlan/?liveList='+(this.liveList ? 1 : 0)+'&date='+this.daySelected+'&page=1&onPage=10000')
        .then((r) =>{
          this.visitPlans = r.data.data;
          if(this.filterUserSpec!==null || this.filterSpec!==null){
            let arr = [];
            this.visitPlans.forEach((v)=>{
              let add = false;

              if(this.filterUserSpec!==null)
                if(v.userSpec!==null)
                  if(v.userSpec.id===this.filterUserSpec.id) add = true;

              if(this.filterSpec!==null){
                if(v.spec!==null)
                  if(v.spec.id===this.filterSpec.id) add = true;
              }
              if(add)
                arr.push(v);
            });

            this.visitPlans = arr;
          }

          if (this.currentDay) {
            this.visitPlans = this.visitPlans.filter(item=>moment(item.begin).isSame(moment(this.daySelected)));
          }

          if (this.branches.length>0){
            this.visitPlans = this.visitPlans.filter(visit=>(visit.userSpec=== null) || this.branches.filter(v2=>v2.id==visit.userSpec.comDiv.comBranch.id).length>0);
          }
   
          this.dataState.stateSuccess();

          //for dataFromCaller
          this.openSearchModalOrAddCurrentUser()
        })
        .catch(e =>{
          this.dataState.stateError();
          Api.responseError(e)
        });
    },
    clientSelected(v){
      this.clientSearchState.modalClose();
      let lL = this.liveList

      if (this.dataFromCaller.type === 'live-list') {
        lL = true
      } else if (this.dataFromCaller.type === 'waiting-list') {
        lL = false
      }

      this.visitPlanState.modalOpen({
        user:v,
        isLiveList: lL
      })
    },
    visitCreate(v){
      this.visitPlanState.modalClose();
      this.$emit('visitCreate', v);
    }
  }
}
</script>