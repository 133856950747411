<template>
<div style="position:absolute; width:100%;">
  <v-toolbar class="bar" dense elevation="0" color="transparent" max-height="48px">
    <v-btn icon small @click="$emit('cancel')">
      <v-icon small>mdi-close</v-icon>
    </v-btn>
    <v-toolbar-title class="body-2 pl-2 font-weight-medium">Перенос визитов</v-toolbar-title>
    <v-spacer/>
  </v-toolbar>

  <v-divider></v-divider>

  <v-sheet :height="height-48" class="overflow-y-auto pb-15">
    <v-list v-if="visits.length>0" class="pa-0">
      <v-list-item
          v-for="(visit, k) in visits" :key="'visit'+k"
          @click="$emit('visit-open',visit.id);">
        <v-list-item-content v-if="visit.user">
          <v-list-item-title>
            <div class="font-weight-medium mb-1">
              {{visit.user.lastName=== null ? '': visit.user.lastName}}
              {{visit.user.firstName=== null ? '': visit.user.firstName}}
              {{visit.user.secondName=== null ? '': visit.user.secondName}}
            </div>
            <div class="caption mb-1">К специалисту{{visit.isLiveList ? '': ' '+$tools.dateTime(visit.begin)}}:</div>
            <v-row no-gutters class="align-center">
              <v-col cols="auto">
                <v-avatar class="user" size="32">
                  <img :src="visit.userSpec.user.imageUrl" v-if="visit.userSpec.user.imageUrl" alt="">
                </v-avatar>
              </v-col>
              <v-col class="pl-2">
                <div class="font-weight-medium">
                  {{visit.userSpec.user.lastName=== null ? '': visit.userSpec.user.lastName}}
                  {{visit.userSpec.user.firstName=== null ? '': visit.userSpec.user.firstName}}
                  {{visit.userSpec.user.secondName=== null ? '': visit.userSpec.user.secondName}}
                </div>
                <div class="caption text-wrap">
                  {{visit.userSpec.spec.name}}
                  <v-icon small>mdi-chevron-left</v-icon>
                  {{visit.comPlace.comRoom.comFloor.comBuilding.comBranch.name}}
                  <div v-if="!$tools.isEmpty(visit.userSpec.commentAdmin)" class="caption">
                    <v-icon class="mr-1" small>mdi-comment-text-outline</v-icon>
                    {{visit.userSpec.commentAdmin}}
                  </div>
                </div>
              </v-col>
            </v-row>
            <div v-if="!$tools.isEmpty(visit.comment)" class="mt-1">
              <div class="caption">Комментарий:</div>
              <div class="caption">{{visit.comment}}</div>
            </div>
          </v-list-item-title>
          <v-btn style="max-width: 100px" color="primary" x-small  @click.stop="$emit('move', {id:visit.id,begin:visit.begin,end:visit.end,user:{lastName:visit.user.lastName,firstName:visit.user.firstName, secondName:visit.user.secondName}})">
            Перенести
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <alert-view v-else icon="mdi-information-outline" text="Не найдено" />
  </v-sheet>
</div>
</template>

<script>


import AlertView from "@/components/AlertView";
export default {
  components: {AlertView},
  props:['visits','height'],
  data: () => ({

  }),
  mounted() {
  },
  watch:{
  },
  methods:{

  }
}
</script>