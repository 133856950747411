<template>
  <v-autocomplete
      v-model="selected"
      :items="items"
      :search-input.sync="text"
      @keyup="state.stateRequest();$tools.throttle(()=>load())"
      :label="typeof title!=='undefined' ? title : 'Услуги'"
      outlined
      dense
      hide-details="auto"
      multiple
      class="mb-4"
      bottom
      item-text="name"
      :filter="(item, queryText, itemText) => {
        let find = 0;
        itemText = itemText.toLocaleLowerCase();
        let arr = queryText.toLocaleLowerCase().split(' ');
        arr.forEach(v=>{
          if(itemText.indexOf(v) > -1)
            find++;
        });
        return find===arr.length;
      }"
      item-value="id"
      return-object
      :menu-props="{top:typeof top!=='undefined'}"
      ref="serviceSelect"
  >
    <template v-slot:append>
      <v-sheet v-if="state.isLoading" width="24" height="24" class="d-flex align-center justify-center">
        <v-progress-circular color="primary" size="16" width="2" indeterminate/>
      </v-sheet>
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title v-if="state.isRequest">Ожидание завершения ввода...</v-list-item-title>
          <v-list-item-title v-else-if="state.isLoading">Поиск...</v-list-item-title>
          <v-list-item-title v-else>Введите текст для поиска...</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item="{item}">
      <v-list-item-content style="max-width:600px" class="my-n1">
        <div>
          <div>
            <v-breadcrumbs class="pa-0 text-no-wrap mt-n1">
              <v-breadcrumbs-item v-if="$tools.notNull(item, 'serviceCat.serviceCat.serviceCat.serviceCat')" class="caption">{{item.serviceCat.serviceCat.serviceCat.serviceCat.name}}</v-breadcrumbs-item>
              <v-breadcrumbs-divider v-if="$tools.notNull(item, 'serviceCat.serviceCat.serviceCat.serviceCat')" class="caption px-1">/</v-breadcrumbs-divider>

              <v-breadcrumbs-item v-if="$tools.notNull(item, 'serviceCat.serviceCat.serviceCat')" class="caption">{{item.serviceCat.serviceCat.serviceCat.name}}</v-breadcrumbs-item>
              <v-breadcrumbs-divider v-if="$tools.notNull(item, 'serviceCat.serviceCat.serviceCat')" class="caption px-1">/</v-breadcrumbs-divider>

              <v-breadcrumbs-item v-if="$tools.notNull(item, 'serviceCat.serviceCat')" class="caption">{{item.serviceCat.serviceCat.name}}</v-breadcrumbs-item>
              <v-breadcrumbs-divider v-if="$tools.notNull(item, 'serviceCat.serviceCat')" class="caption px-1">/</v-breadcrumbs-divider>

              <v-breadcrumbs-item v-if="$tools.notNull(item, 'serviceCat')" class="caption">{{item.serviceCat.name}}</v-breadcrumbs-item>
            </v-breadcrumbs>
          </div>
          <v-list-item-title class="text-wrap">
            {{item.name}}
          </v-list-item-title>
        </div>
      </v-list-item-content>
    </template>
    <template v-slot:selection="{item}">
      <v-chip class="full-width mt-2" small style="width:95%;height:initial">
        <v-row no-gutters class="align-center full-width">
          <v-col class="text-wrap">
            <span>{{item.name}}</span>
          </v-col>
          <v-col cols="auto"><v-icon small @click.stop="selected=selected.filter((v)=>v.id!==item.id)">mdi-close-circle</v-icon></v-col>
        </v-row>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>

import State from "@/plugins/state";
import Api from "@/Api";

export default {
  props:['title','top'],
  data:()=>({
    selected:[],
    items:[],
    text:null,
    state: new State(),
  }),
  watch:{
    selected(){
      this.$emit('selected', this.selected);
    }
  },
  methods:{
    load(){
      if(!this.$tools.isEmpty(this.text)){
        let query = `
          query Query($search: String){
            Service(search: $search){
              id
              name
              priceTotal
              serviceCat{
                id
                name
                isProgram
                serviceCat{
                  id
                  name
                  isProgram
                  serviceCat{
                    id
                    name
                    isProgram
                    serviceCat{
                      id
                      name
                      isProgram
                      serviceCat{
                        id
                        name
                        isProgram
                        serviceCat{
                          id
                          name
                          isProgram
                          serviceCat{
                            id
                            name
                            isProgram
                            serviceCat{
                              id
                              name
                              isProgram
                              serviceCat{
                                id
                                name
                                isProgram
                                serviceCat{
                                  id
                                  name
                                  isProgram
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `;
        let queryVars = {
          search:this.text,
        };

        this.state.stateLoading();
        Api.graphql(query, queryVars, (data)=>{
          this.state.stateSuccess();
          this.items = this.$tools.mergeUniqById(this.selected, data.Service);
          this.$refs.serviceSelect.updateMenuDimensions()
        }, (e)=>{
          this.state.stateError(e)
        });
      }
    },

    selectedClear(){
      this.selected = [];
    }
  }
}
</script>

