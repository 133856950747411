<template>
  <dialog-view
      :open="dataState.isModalOpen"
      :title="searchState.isModalOpen&&!userSearchMatch ? 'Новый визит' : searchState.isModalOpen&&userSearchMatch ? 'Найдено совпадение!' : userCreateState.isModalOpen ? 'Новый клиент' : ''"
      @cancel="close()"
      :cancelNotClose="!searchState.isInit"
      :key="componentKey"
  >
    <template v-slot:content>
      <div v-show="searchState.isModalOpen">
        <v-row v-if="searchState.isInit">
          <v-col v-if="visitCreateVisitPlan!==null" cols="12">
            <v-sheet rounded outlined class="pa-4">
              <div>Создать визит из {{ visitCreateVisitPlan.isLiveList? 'листа живой очереди' : 'листа ожидания'}}?</div>
              <div>Клиент: {{visitCreateVisitPlan.user.lastName}} {{visitCreateVisitPlan.user.firstName}} {{visitCreateVisitPlan.user.secondName}}</div>
              <div class="mt-2">
                <v-btn small depressed class="mr-2" @click="visitCreateVisitPlan=null;close()">Отмена</v-btn>
                <v-btn small depressed color="primary" @click="visitCreateByUser(visitCreateVisitPlan.user, visitCreateVisitPlan.id)">Создать</v-btn>
              </div>
            </v-sheet>
          </v-col>
          <v-col v-if="visitMove!==null" cols="12">
            <v-sheet rounded outlined class="pa-4">
              <div>Перенести визит c {{$tools.dateTimeFormat(visitMove.begin, 'DD.MM.YYYY HH:mm')}}<br/>на {{$tools.dateTimeFormat(begin, 'DD.MM.YYYY HH:mm')}}?</div>
              <div>Клиент: {{visitMove.user.lastName}} {{visitMove.user.firstName}} {{visitMove.user.secondName}}</div>
              <div class="mt-2">
                <v-btn small depressed class="mr-2" @click="visitMove=null;close()">Отмена</v-btn>
                <v-btn small depressed color="primary" @click="visitMoveAction()">Перенести</v-btn>
              </div>
            </v-sheet>
          </v-col>
          <v-col cols="12">
            Укажите номер телефона пациента или Ф.И.О
          </v-col>
          <v-col cols="12">
            <!-- <v-text-field
                ref="searchPhone"
                v-model="searchPhone"
                type="tel" dense clearable label="Номер телефона" outlined hide-details="auto" v-mask="'+7 (###) ###-##-##'"/> -->
                <phone-field ref="searchPhone" :phone.sync="searchPhone" label="Телефон"/>
          </v-col>
          <v-col cols="12">
            <v-text-field
                @keyup="$tools.firstLatterUppercase($event)"
                v-model="searchLastName"
                dense clearable label="Фамилия" outlined hide-details="auto"/>
          </v-col>
          <v-col cols="12">
            <v-text-field
                @keyup="$tools.firstLatterUppercase($event)"
                v-model="searchFirstName"
                dense clearable label="Имя" outlined hide-details="auto"/>
          </v-col>
          <v-col cols="12">
            <v-text-field
                @keyup="$tools.firstLatterUppercase($event)"
                v-model="searchSecondName"
                dense clearable label="Отчество" outlined hide-details="auto"/>
          </v-col>
        </v-row>
        <div v-if="searchState.isLoading" class="py-4 text-center">
          <v-progress-circular indeterminate color="primary"/>
        </div>
        <div v-if="searchState.isSuccess">
          <div v-if="searchUsers.length>0">
            <v-list class="pa-0 ma-n4">
              <v-list-item v-for="(item, index) in searchUsers" :key="index" link @click="visitCreateByUser(item)">
                <v-list-item-title class="py-2">
                  <h3 class="font-weight-regular mb-2">{{item.lastName}} {{item.firstName}} {{item.secondName}}</h3>
                  <div class="font-weight-regular">
                    <span class="mr-4"><v-icon class="mr-2" small>mdi-account-outline</v-icon>{{item.userProfile.gender==='male' ? 'Мужчина' : 'Женщина'}}</span>
                    <span class="mr-4"><v-icon class="mr-2" small>mdi-calendar-star</v-icon>{{item.userProfile.birth|date}}</span>
                    <span><v-icon class="mr-2" small>mdi-phone-outline</v-icon>{{item.phone}}</span>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
          <div v-else>
            <alert-view icon="mdi-information-outline" text="Не найдено" />
          </div>
        </div>
      </div>
      <v-form v-show="userCreateState.isModalOpen" ref="userCreateForm">
        <v-row>
          <v-col cols="12">
            <v-text-field @keyup="$tools.firstLatterUppercase($event)" v-model="userCreateLastName" label="Фамилия" :rules="formFieldRules.required" outlined dense hide-details="auto"/>
          </v-col>
          <v-col cols="12">
            <v-text-field @keyup="$tools.firstLatterUppercase($event)" v-model="userCreateFirstName" label="Имя" :rules="formFieldRules.required" outlined dense hide-details="auto"/>
          </v-col>
          <v-col cols="12">
            <v-text-field @keyup="$tools.firstLatterUppercase($event)" v-model="userCreateSecondName" label="Отчество" outlined dense hide-details="auto"/>
          </v-col>
          <v-col cols="12">
            <v-select v-model="userCreateGender" :items="[{name:'Женщина', value:'female'},{name:'Мужчина', value:'male'}]" item-text="name" item-value="value" label="Пол" outlined dense hide-details="auto"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="userCreateBirth" label="Дата рождения" outlined dense hide-details="auto" v-mask="'##.##.####'">
              <template v-slot:append>
                <div class="caption text-no-wrap" style="margin-top:2px">{{birthYear}}</div>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <!-- <v-text-field v-model="userCreatePhone" label="Телефон" :rules="formFieldRules.required" outlined dense hide-details="auto" v-mask="'+7 (###) ###-##-##'"/> -->
            <phone-field :phone.sync="userCreatePhone" label="Телефон" required/>
          </v-col>
          <v-col cols="12">
            <v-select v-model="userCreatePromoSource" :rules="formFieldRules.required" :items="promoSources" :loading="promoSourcesState.isLoading" item-text="name" item-value="id" label="Рекламный источник" outlined dense hide-details="auto"/>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <div v-if="searchState.isModalOpen">
        <div v-if="searchState.isInit">
          <v-btn @click="search()" color="primary" text>Поиск</v-btn>
        </div>
        <div v-if="searchState.isSuccess">
          <v-btn v-if="!userSearchMatch"
            @click="
              searchState.modalClose();
              userCreateState.modalOpen();
              userCreateState.stateInit();
              userCreateLastName=searchLastName?.trim();
              userCreateFirstName=searchFirstName?.trim();
              userCreateSecondName=searchSecondName?.trim();
              userCreateGender='female'
              userCreateBirth=null;
              userCreatePhone=searchPhone;
              $refs.userCreateForm.resetValidation();
              promoSourcesLoad();
            " color="primary" text
          >Новый клиент</v-btn>


        <btn-question v-if="userSearchMatch" @click="visitCreateByNewUser(true)" :loading="userCreateState.isLoading"  color="primary" text  size="default" class="mr-2" title="Создать нового клиента?">
          <template v-slot:default>Создать</template>
          <template v-slot:content>
              <div class="mx-5 my-5">
                <v-alert
                  outlined
                  type="warning"
                  prominent
                  border="left"
                >
                Было найдено совпадение, Вы уверены, что хотите создать нового пользователя?
                </v-alert>
              </div>
          </template>
        </btn-question>
        </div>
      </div>
      <div v-if="userCreateState.isModalOpen">
        <v-btn @click="visitCreateByNewUser()" color="primary" text :loading="userCreateState.isLoading">Создать</v-btn>
      </div>
    </template>
  </dialog-view>
</template>


<script>
import State from "@/plugins/state";
import DialogView from "@/components/DialogView";
import {FormFieldRules} from "@/plugins/formFieldRules";
import moment from "moment";
import axios from "axios";
import Api from "@/Api";
import AlertView from "@/components/AlertView";
import PhoneField from "@/components/PhoneField";
import BtnQuestion from "@/components/btnQuestion.vue";

export default {
  components: {AlertView, DialogView, PhoneField, BtnQuestion},
  props:['state','dataFromCaller'],
  data: () => ({
    dataState: new State(),
    formFieldRules: FormFieldRules,

    begin:null,
    end:null,
    comPlaceId:null,

    searchState: new State(),
    searchPhone:null,
    searchFirstName:null,
    searchLastName:null,
    searchSecondName:null,
    searchUsers:[],
    visitCreateVisitPlan:null,
    visitMove:null,

    userCreateState: new State(),
    userCreateLastName:null,
    userCreateFirstName:null,
    userCreateSecondName:null,
    userCreateGender:null,
    userCreateBirth:null,
    userCreatePhone:null,

    userCreatePromoSource:undefined,
    promoSourcesState: new State(),
    promoSources:[],

    birthYear:null,
    userSearchMatch:false,
    componentKey:0
  }),
  watch:{
    state: {
      deep: true,
      handler() {
        if(this.state.isModalOpen){
          this.dataState.modalOpen();

          this.searchPhone='+7';
          this.searchFirstName=null;
          this.searchLastName=null;
          this.searchSecondName=null;
          this.searchUsers = [];
          this.begin = moment(this.state.modalData.begin);
          this.end = moment(this.state.modalData.end);
          this.comPlaceId = this.state.modalData.comPlace.id;
          this.visitCreateVisitPlan = this.state.modalData.visitCreateVisitPlan;
          this.visitMove = this.state.modalData.visitMove;

          this.searchState.modalOpen();
          this.searchState.stateInit();


          if (this.dataFromCaller.user === null&&this.dataFromCaller.phone === null) {
            setTimeout(()=>{
              this.$refs.searchPhone.$el.querySelector('input').focus();
            }, 200);
          }
         

          if (this.dataFromCaller.phone!==null){
            this.searchPhone = this.dataFromCaller.phone
            //this.search();

            this.searchState.modalClose();
            this.userCreateState.modalOpen();
            this.userCreateState.stateInit();
            this.userCreateLastName=this.searchLastName?.trim();
            this.userCreateFirstName=this.searchFirstName?.trim();
            this.userCreateSecondName=this.searchSecondName?.trim();
            this.userCreateGender='female'
            this.userCreateBirth=null;
            this.userCreatePhone=this.searchPhone;

            this.promoSourcesLoad();
          }  
          if (this.dataFromCaller.user !== null && this.dataFromCaller.type === null) {
            this.visitCreateByUser(this.dataFromCaller.user, null, this.dataFromCaller.visitServices)
          }
          
        } else {
          //reset
          this.componentKey++;
        }
      },
    },
  },
  methods: {
    search(createNewUserParams){
      this.searchState.stateLoading();
      this.userSearchMatch = false;
      //если не переданы параметры из формы создания нового клиента
      let params = {};
      if (!createNewUserParams) {
        if(!this.$tools.isEmpty(this.searchPhone))
          if(this.searchPhone.length>2) params.phone = this.searchPhone;
        if(!this.$tools.isEmpty(this.searchFirstName)) params.firstName = this.searchFirstName?.trim();
        if(!this.$tools.isEmpty(this.searchLastName)) params.lastName = this.searchLastName?.trim();
        if(!this.$tools.isEmpty(this.searchSecondName)) params.secondName = this.searchSecondName?.trim();
      } else {
        params = createNewUserParams;
      }
      axios.get(Api.host+'/user/?filters='+JSON.stringify(params)+'&sortBy=lastName').then((r)=> {
        this.searchUsers = r.data.data;
        this.searchState.stateSuccess();

        //если переданы параметры из формы создания нового клиента
        if(createNewUserParams&&this.searchUsers.length>0) {
          this.userSearchMatch = true;
          this.userCreateState.modalClose();
          this.searchState.modalOpen();
        } else if (createNewUserParams&&this.searchUsers.length===0) {
          this.visitCreateByNewUser(true);
        }
      })
      .catch(()=>{
        this.searchState.stateError();
      });
    },

    promoSourcesLoad(){
      this.promoSourcesState.stateLoading();
      axios.get(Api.host+'/user/promoSource/')
        .then(r=> {
          this.promoSources = r.data;
          this.promoSourcesState.stateSuccess();
        })
        .catch(e=>{
          this.promoSourcesState.stateError();
          console.log(e)
        });
    },

    visitCreateByUser(user, visitPlanId=null, visitServices=[]){
      this.searchState.stateLoading();

      axios.post(Api.host+'/visit/', {
        status:'pre',
        begin: this.begin.format('YYYY-MM-DD HH:mm:ss'),
        end: this.end.format('YYYY-MM-DD HH:mm:ss'),
        comPlace: this.comPlaceId,
        userId:user.id,
        visitPlanId:visitPlanId,
      })
          .then((r)=> {
            this.searchState.modalClose();
            this.searchState.stateSuccess();

            this.dataState.modalClose();
         
            if (visitServices.length>0) {
              Api.service.visit.visitServiceAdd(r.data.id, visitServices, ()=> {
                localStorage.setItem('visitServicesAddedFromMedplan', JSON.stringify(visitServices));//for visitServices state update in MedPlan
                this.$emit('success', r.data.id);
              },(e)=> {
                console.error(e);
              });
            }  else {
              this.$emit('success', r.data.id);
            }

          })
          .catch((e) =>{
            this.searchState.modalClose();
            this.searchState.stateSuccess();
            console.log(e);
            this.dataState.modalClose();
            this.$store.commit('alertError', e.response.data.message);
            this.$emit('success', null);
          });
        
        //clear data from caller
        this.$emit('clear-data-from-caller')
    },

    visitCreateByNewUser(force=false){
      if (this.$refs.userCreateForm.validate()) {

        if (!force) {
          //Ищем еще раз перед созданием клиента по ФИО
          let params = {};
          params.firstName = this.userCreateFirstName?.trim();
          params.lastName = this.userCreateLastName?.trim();
          params.secondName = this.userCreateSecondName?.trim();

          this.search(params);
        }
        if (this.searchUsers.length===0||force===true) {
          this.userCreateState.stateLoading();
          
          let userBirth = null;
          if(this.userCreateBirth) {
            userBirth = moment(this.userCreateBirth, 'DD.MM.YYYY').format('YYYY-MM-DD')
          }
        
          axios.post(Api.host+'/visit/', {
            status:'pre',
            begin: this.begin.format('YYYY-MM-DD HH:mm:ss'),
            end: this.end.format('YYYY-MM-DD HH:mm:ss'),
            comPlace: this.comPlaceId,
            userPhone: this.userCreatePhone,
            userFirstName: this.userCreateFirstName?.trim(),
            userLastName: this.userCreateLastName?.trim(),
            userSecondName: this.userCreateSecondName?.trim(),
            userProfileGender: this.userCreateGender,
            userProfileBirth: userBirth,
            userProfilePromoSource: this.userCreatePromoSource
          })
              .then((r)=> {
                this.userCreateState.stateSuccess();
                this.userCreateState.modalClose();

                this.dataState.modalClose();
                this.$emit('success', r.data.id);
              })
              .catch((e)=>{
                this.userCreateState.stateError();

                this.dataState.modalClose();
                this.$store.commit('alertError', e.response.data.message);
                this.$emit('success', null);
              });
        }    
      }
    },
    visitMoveAction(){
      let begin = moment(this.begin).unix();
      // let diff = moment(this.visitMove.end).diff(moment(this.visitMove.begin), 'seconds');
      // let end = begin+diff;
      let end  = moment(this.end).unix();

      begin = moment.unix(begin).format('YYYY-MM-DD HH:mm:ss');
      end = moment.unix(end).format('YYYY-MM-DD HH:mm:ss');
      axios.put(Api.host+'/visit/'+this.visitMove.id+'/', {
        begin: begin,
        end: end,
        comPlace:this.state.modalData.comPlace.id
      })
          .then((r)=> {
            this.visitMove = null;

            this.searchState.modalClose();
            this.searchState.stateSuccess();

            this.dataState.modalClose();
            this.$emit('success', r.data.id);
          })
          .catch((e) =>{
            this.searchState.stateError();
            this.$store.commit('alertError', e.response.data.message);
          });
    },

    close(){
      if(this.userCreateState.isModalOpen) {
        this.userCreateState.modalClose();
        this.searchState.modalOpen();
        this.searchState.stateSuccess();
      } else if(this.searchState.isModalOpen) {
        if(this.searchState.isSuccess){
          this.searchState.stateInit();
        } else{
          this.dataState.modalClose();
          this.$emit('cancel');
        }
      }
      this.userSearchMatch = false;
    }
  }
}
</script>